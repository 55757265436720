import * as React from "react"

import {graphql} from "gatsby";

import cx from "classnames";
import Button from "@material-ui/core/Button/Button";
import {signUpUrl} from "../utils/constants";
import {SignUpButton} from "./SignUpButton";

const style = require("../styles/fullWidthContent.module.scss");

interface IFullWidthContentProps {
    darkMode: boolean,
    featuredWords?: number[],
    title?: string,
    imgSrc?: string,
    className?: string;
    signInButton?: boolean;
    alightRight?: boolean;
}

export default class FullWidthContent extends React.Component<IFullWidthContentProps, {}> {
    public render() {
        return (
            <div
                className={cx(this.props.className, style.FullWidthBanner, "fluid-container", {[style.AlignLeft]: this.props.alightRight}, {[style.DarkMode]: this.props.darkMode})}>
                <div className={cx('container', style.Container)}>
                    <div className={cx("row")}>
                        <div className={cx("text-center col-12")}>
                            {this.props.title &&
                            <h3 className={style.ContentTitle}>
                                {this.props.title.split(' ')
                                    .map((word, index) =>
                                        (this.props.featuredWords || []).indexOf(index) !== -1 ?
                                            <strong> {word}</strong> : ' ' + word)}
                            </h3>}
                        </div>
                    </div>
                    <div
                        className={cx("row")}>
                        <div
                            className={cx(style.Content, {'col-sm-12 col-md-6': !!this.props.imgSrc}, {'col-sm-12': !this.props.imgSrc}, {'order-1 order-md-2': this.props.alightRight})}>

                            {this.props.children}
                            {this.props.signInButton && <div className={style.ButtonWrapper}>
                                <SignUpButton className={style.SignUpBtn}/></div>}
                        </div>
                        <div
                            className={cx(style.ContentImageWrapper, {'col-sm-12 col-md-6': !!this.props.imgSrc}, {'col-sm-12': !this.props.imgSrc}, {'order-2 order-md-1': this.props.alightRight})}>
                            {this.props.imgSrc &&
                            <img src={this.props.imgSrc} className={style.ContentImage} alt={this.props.title || ""}/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
