import * as React from "react"

import {graphql} from "gatsby";

import cx from "classnames";
import Button from '@material-ui/core/Button';
import DashboardOutlined from "@material-ui/icons/DashboardOutlined";
import MobileScreenShare from "@material-ui/icons/MobileScreenShare";
import Chat from "@material-ui/icons/Chat";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import TravelCard from "@material-ui/icons/CardTravel";
import FullWidthContent from "./fullWidthContent";
import AppDownloadButtons from "./AppDownloadButtons";
import {BarChart, Security} from "@material-ui/icons";

const style = require("../styles/features.module.scss");

export default class Features extends React.Component<any, {}> {
    public render() {
        return (
            <div className={cx(style.Features, "container")} id={"produkt"}>
                <FullWidthContent darkMode={false} title={'Czym jest LOGGY?'}
                                  featuredWords={[2]}
                                  className={style.About}
                                  signInButton={false}>

                    <p>
                        Loggy to platforma komunikacyjna przeznaczona dla branży transportowej, która pozwala na łatwe
                        zarządzanie komunikacją z kierowcami bez potrzeby instalowania kosztownego sprzętu w samochodach
                        - wystarczy komputer oraz telefon z dostępem do internetu!

                    </p>

                </FullWidthContent>
                <div className={cx("row", "align-items-start")}>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <MobileScreenShare color={'primary'} className={style.FeatureIcon}/>
                        <h4>Aplikacja dla kierowców</h4>
                        <p>Prosta aplikacja mobilna dla kierowców dostępna na platformy iOS oraz Android.</p>
                    </div>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <TravelCard color={'primary'} className={style.FeatureIcon}/>
                        <h4>Delegacje kierowców</h4>
                        <p>Intyuicyjny proces zarządania delegacjami przez kierowców z poziomu aplikacji mobilnej.</p>
                    </div>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <BusinessCenter color={'primary'} className={style.FeatureIcon}/>
                        <h4>Zarządzanie zleceniami</h4>
                        <p>Rozbudowany panel administracyjny umożliwiający zarządzanie zaawansowanymi zleceniami.</p>
                    </div>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <Chat color={'primary'} className={style.FeatureIcon}/>
                        <h4>Komunikacja z kierowcą</h4>
                        <p>Możliwość wysyłania wiadomości do kierowców oraz ich punktowa geolokalizacja.</p>
                    </div>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <BarChart color={'primary'} className={style.FeatureIcon}/>
                        <h4>Szczegółowe statystki</h4>
                        <p>Dokładne statystki pozwalające na optymalizację procesów w Twojej firmie.</p>
                    </div>
                    <div className={cx(style.Feature, "col-sm-6", "col-md-4")}>
                        <Security color={'primary'} className={style.FeatureIcon}/>
                        <h4>Bezpiczeństwo danych</h4>
                        <p>Dzięki algorytmom szyfrującym Twoje dane są z nami bezpieczne.</p>
                    </div>
                </div>
            </div>
        )
    }
}
