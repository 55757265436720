import * as React from "react"
import '../styles/global.scss';
import Features from "../components/features";
import FullWidthContent from "../components/fullWidthContent";
import PricingTable from "../components/pricingTable";
import mobileImg from "../images/mobile-img2.png";
import messagesImg from "../images/messages-img.png";
import delegationsImg from "../images/delegations-img.png";
import jobsImg from "../images/jobs-img.png";
import statsImg from "../images/stats-img.png";
import styles from "../styles/index.module.scss";
import AppDownloadButtons from "../components/AppDownloadButtons";
import Contact from "../components/contact";
import Layout from "../components/layout";
import sample from "../images/delegacja.pdf";

export default class IndexPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={true}>
                <Features/>
                <section id="funkcje">
                    <FullWidthContent darkMode={true} title={'Intuicyjna aplikacja mobilna dla kierowców'}
                                      featuredWords={[1, 2]}
                                      className={styles.MobileApp}
                                      signInButton={false}
                                      imgSrc={mobileImg}>
                        <p>Intuicyjna i prosta w obsłudze aplikacja mobilna dla kierowców pozwala na szybką,
                            dwustronną
                            komunikacje, jednocześnie ograniczając zużycie transmisji danych do minimum.
                        </p>

                        <AppDownloadButtons/>
                    </FullWidthContent>
                    <FullWidthContent darkMode={false} title={'Delegacje kierowców'} alightRight={true}
                                      featuredWords={[1]}
                                      signInButton={true}
                                      imgSrc={delegationsImg}>
                        <p>Dedykowana aplikacja mobilna dla kierowców pozwala na proste i szybkie zarządzanie
                            delegacjami.</p>

                        <p>Już teraz kierowcy w Twojej firmie mogą na bieżąco tworzyć delegacje, załączać do nich
                            zdjęcia paragonów, a następnie wysyłać je za pomocą jednego kliknięcia, bez zbędnej
                            papierologii. </p>

                        <p><a href={sample} target={'_blank'}>Zobacz</a> jak wygląda PDF wygenerowany za pomocą platformy Loggy.pl.</p>

                    </FullWidthContent>
                    <FullWidthContent darkMode={true} title={'Łatwe zarządzanie zleceniami'} alightRight={false}
                                      featuredWords={[1]}
                                      signInButton={true}
                                      imgSrc={jobsImg}>
                        <p>Zaawansowany panel administracyjny umożliwia tworzenie zleceń dopasowanych do potrzeb
                            oraz
                            specyfiki Twojej działalności.</p>
                        <p>Możesz w łatwy sposób dodawać własne statusy zleceń oraz zawrzeć wszystkie niezbędne
                            informacje na temat zlecenia w jednym miejscu!</p>

                        <p><strong>Wszystkie wprowadzane informacje na temat zlecenia są widoczne tylko dla Ciebie i
                            współpracowników z Twojej firmy! Nie musisz się martwić, że wpadną w niepowołane
                            ręce. </strong></p>
                    </FullWidthContent>

                    <FullWidthContent darkMode={false} title={'Szybka komunikacja z kierowcą'} featuredWords={[1]}
                                      signInButton={true}
                                      alightRight={true}
                                      imgSrc={messagesImg}>
                        <p>Komunikacja z kierowcami jeszcze nigdy nie była szybsza! W każdej chwili możesz wysyłać
                            wiadomości do poszczególnych kierowców jak i do wszystkich za jednym razem.</p>
                        <p>Kierowca w każdej chwili może załączyć zdjęcia niezbędnych dokumentów do zlecenia.</p>
                        <p>Dzięki punktowej geolokalizacji podczas aktulizacji zlecenia przez kierowcę w kilka
                            sekund
                            możesz
                            uzyskać wgląd w lokalizację wszystkich aktywnych kierowców.</p>

                    </FullWidthContent>

                    {/*<FullWidthContent darkMode={true} title={'Szczegółowe statystyki'} alightRight={false}*/}
                    {/*featuredWords={[1]}*/}
                    {/*signInButton={true}*/}
                    {/*imgSrc={statsImg}>*/}
                    {/*<p>Rozbudowana sekcja statystyk pozwoli Ci w dokładny sposób wyciągnąć wnioski z wydajności*/}
                    {/*Twoich kierowców</p>*/}

                    {/*<p>Szczegółowe wykresy ułatwią Ci optymalizację procesów w Twojej firmie!</p>*/}

                    {/*</FullWidthContent>*/}

                </section>
                <section id={"cennik"}>
                    <PricingTable/>
                </section>

                <section id={"kontakt"}>
                    <Contact/>
                </section>
            </Layout>
        )
    }
}
