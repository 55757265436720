import * as React from "react"


import cx from "classnames";
import appStoreImg from "../images/appstore.png";
import googleStoreImg from "../images/gplay.png";
import {appStoreUrl, googlePlayUrl} from "../utils/constants";

// impo rt Button from '@material-ui/core/Button';

const style = require("../styles/appDownloadButtons.module.scss");


export default class AppDownloadButtons extends React.Component<{}> {

    public render() {
        return (
            <ul className={cx(style.AppDownloadButtons)}>
                <li>
                    <a href={appStoreUrl} title="Pobierz z App Store" target={"_blank"}>
                        <img src={appStoreImg} alt={"Pobierz z App Store"}/>
                    </a>
                </li>
                <li>
                    <a href={googlePlayUrl} title={"Pobierz z Google Play"} target={"_blank"}>
                        <img src={googleStoreImg} alt={"Pobierz z Google Play"}/>
                    </a>
                </li>
            </ul>
        )
    }
}
