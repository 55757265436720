import * as React from "react"

import {graphql} from "gatsby";

import cx from "classnames";
import Button from "@material-ui/core/Button/Button";
import {plans, featuresList} from "../utils/constants";
import {SignUpButton} from "./SignUpButton";
import {SetUpCallButton} from "./SetUpCall";
// import Button from "@material-ui/core/Button/Button";

const style = require("../styles/pricingTable.module.scss");

// const featuresList = [
//     "Zarządzanie zleceniami",
//     "Zarządzanie kierowcami",
//     "Integracja SMS",
//     "Wiadomości dla kierowców",
//     "Geolokalizacja kierowców",
//     "Powiadomienia PUSH",
//     "Powiadomienia e-mail dla spedytorów",
//     "Szczegółowe statystyki",
//     "Raporty PDF",
//     "Własne statusy zleceń",
//     "Powiadomienia e-mail dla podmiotów zewnętrznych",
//     "Chat grupowy",
// ];
//
// const plans = [
//     {
//         description: 'Pakiet dla małych firm',
//         driversNumber: 10,
//         managersNumber: 5,
//         name: 'Basic',
//         price: 150,
//     },
//     {
//         description: 'Pakiet dla małych firm',
//         driversNumber: 10,
//         managersNumber: 5,
//         name: 'Basic',
//         price: 150,
//     },
//     {
//         description: 'Pakiet dla małych firm',
//         driversNumber: 10,
//         managersNumber: 5,
//         name: 'Basic',
//         price: 150,
//     }
// ]

export default class PricingTable extends React.Component<any, {}> {
    public render() {
        return (
            <div
                className={cx(style.PricingTable, "fluid-container")}>
                <div className={'container'}>
                    <div className={cx("row", "align-items-end")}>
                        <div className={cx('col-12', style.PricingTitle)}>
                            <h3>Przetestuj <strong>za darmo</strong> przez 30 dni!</h3>
                            <p>W ramach testu oferujemy korzystanie z aplikacji przez 30 dni zupełnie za darmo!</p>
                            <SignUpButton/>
                        </div>
                    </div>
                    <div className={cx("row")}>

                        <div className={cx(`col-12 col-sm-6 col-md-4`)}>
                            <div className={style.PricingOption}>
                                <h5 className={style.PricingOptionTitle}>{plans[0].name}</h5>
                                <div className={style.PricingOptionSummary}>
                                    <strong className={style.Price}>{plans[0].price}zł</strong>
                                    <span className={style.PriceNote}>miesięcznie</span>
                                    <div className={style.PricingOptionDescription}>
                                        {plans[0].description}
                                    </div>
                                    <span
                                        className={style.PricingOptionMark}>Do <strong>{plans[0].driversNumber}</strong> kierowców</span>
                                    <div className={style.PricingNote}>Bez dodatkowych opłat</div>
                                </div>
                                <ul className={style.PricingOptionFeatures}>
                                    {plans[0].features.map(feature => (<li>{feature}</li>))}
                                    <li>{plans[0].storageSpace} przestrzeni na pliki</li>
                                </ul>
                                <div className={style.SignUpWrapper}>
                                    <SignUpButton/>
                                </div>
                            </div>
                        </div>


                        <div className={cx(`col-12 col-sm-6 col-md-4`)}>
                            <div className={style.PricingOption}>
                                <h5 className={style.PricingOptionTitle}>{plans[1].name}</h5>
                                <div className={style.PricingOptionSummary}>
                                    <strong className={style.Price}>{plans[1].price}zł</strong>
                                    <span className={style.PriceNote}>miesięcznie</span>
                                    <div className={style.PricingOptionDescription}>
                                        {plans[1].description}
                                    </div>
                                    <span
                                        className={style.PricingOptionMark}>Do <strong>{plans[1].driversNumber}</strong> kierowców</span>
                                    <div className={style.PricingNote}>Najpopularniejszy</div>
                                </div>
                                <ul className={style.PricingOptionFeatures}>
                                    {plans[1].features.map(feature => (<li>{feature}</li>))}
                                    <li>{plans[1].storageSpace} przestrzeni na pliki</li>
                                </ul>
                                <div className={style.SignUpWrapper}>
                                    <SignUpButton/>
                                </div>
                            </div>
                        </div>


                        <div className={cx(`col-12 col-sm-6 col-md-4`)}>
                            <div className={style.PricingOption}>
                                <h5 className={style.PricingOptionTitle}>{plans[2].name}</h5>
                                <div className={style.PricingOptionSummary}>
                                    <strong className={style.Price}>{plans[2].price}zł</strong>
                                    <span className={style.PriceNote}>miesięcznie</span>
                                    <div className={style.PricingOptionDescription}>
                                        {plans[2].description}
                                    </div>
                                    <span
                                        className={style.PricingOptionMark}>Do <strong>{plans[2].driversNumber}</strong> kierowców</span>
                                    <div className={style.PricingNote}><a href="/#wiecej">Potrzebujesz więcej?</a></div>
                                </div>
                                <ul className={style.PricingOptionFeatures}>
                                    {plans[2].features.map(feature => (<li>{feature}</li>))}
                                    <li>{plans[2].storageSpace} przestrzeni na pliki</li>
                                </ul>
                                <div className={style.SignUpWrapper}>
                                    <SignUpButton/>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className={'row'}>
                        <div className={cx('col-sm-12', style.PriceInfo)}>
                            <p>Podane ceny, są cenami netto, do których należy doliczyć 23% VAT.</p>
                        </div>
                    </div>

                    <div className={cx("row", "justify-content-center mb-3")} id={"wiecej"}>
                        <div className={cx('col-8', style.PricingTitle)}>
                            <h3>Potrzebujesz oferty indywidualnej?</h3>
                            <h4 className={'text-center'}>Jeżeli nasza ofertę jest niewystarczająca dla Twojej firmy,
                                umów się z naszym doradcą w
                                celu omówienia indywidualnego podejścia do Twoich potrzeb</h4>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={cx('col-sm-12', style.PricingTableCTA)}>
                            <SetUpCallButton variant={'contained'} color="primary" fullWidth={false}
                                             label={"Umów się na rozmowę z doradcą"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
