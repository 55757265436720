import * as React from "react"

import cx from "classnames";
import Button from "@material-ui/core/Button/Button";

import {SignUpButton} from "./SignUpButton";
import {getPhoneNumber} from "../utils/constants";
// import Button from "@material-ui/core/Button/Button";

const style = require("../styles/contact.module.scss");

export default class Contact extends React.Component<any, {}> {
    public render() {
        return (
            <div
                className={cx(style.Contact, "fluid-container")}>
                <div className={'container'}>
                    <div className={cx("row", "align-items-end")}>
                        <div className={cx('col-12', style.PricingTitle)}>
                            <h3>Masz pytania? <strong>Skontaktuj się z nami!</strong></h3>
                            <h4 className={style.MailAddress}><a href='mailto:biuro@loggy.pl'>biuro@loggy.pl</a></h4>
                            <h4 className={style.MailAddress}><a href={`tel:${getPhoneNumber()}`}>{getPhoneNumber()}</a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
